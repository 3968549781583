<template>
    <div class="button-row d-flex justify-content-end mt-4">
  <button class="btn btn-sm btn-danger" type="button" @click="deleteItem(user.id)">
    Delete Customer
</button>
          </div>

<div class="p-4">
  <Form role="form" :validation-schema="schema" @submit="handleUpdate">
        <div class="row">
          <div class="col-md-6">
        <div class="input-group input-group-static mb-4">
          <label>Company Name</label>
          <Field
            type="text"
            class="form-control"
            v-model="user.company_name"
            name="company_name"
            id="company_name"
          />
          <ErrorMessage name="company_name" class="text-danger" />
        </div>
      </div>
        <div class="col-md-6">
        <div class="input-group input-group-static mb-4">
          <label>Email</label>
          <Field
            type="text"
            class="form-control"
            v-model="user.email"
            name="email"
            id="email" readonly
          />
          <ErrorMessage name="email" class="text-danger" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="input-group input-group-static mb-4">
          <label>Name</label>
          <Field
            type="text"
            class="form-control"
            v-model="user.name"
            name="name"
            id="name"
          />
          <ErrorMessage name="name" class="text-danger" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="input-group input-group-static mb-4">
          <label>City</label>
          <Field
            type="text"
            class="form-control"
            v-model="user.city"
            name="city"
            id="city"
          />
          <ErrorMessage name="city" class="text-danger" />
        </div>
      </div>
     
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="input-group input-group-static mb-4">
          <label>Address</label>
          <Field
            type="text"
            class="form-control"
            v-model="user.address"
            name="address"
            id="address"
          />
          <ErrorMessage name="address" class="text-danger" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="input-group input-group-static mb-4">
          <label>Pin</label>
          <Field
            type="text"
            class="form-control"
            v-model="user.pin"
            name="pin"
            id="pin"
          />
          <ErrorMessage name="pin" class="text-danger" />
        </div>
      </div>
     
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="input-group input-group-static mb-4">
          <label>Bank</label>
          <Field
            type="text"
            class="form-control"
            v-model="user.bank"
            name="bank"
            id="bank"
          />
          <ErrorMessage name="bank" class="text-danger" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="input-group input-group-static mb-4">
          <label>IBAN</label>
          <Field
            type="text"
            class="form-control"
            v-model="user.iban"
            name="iban"
            id="iban"
          />
          <ErrorMessage name="iban" class="text-danger" />
        </div>
      </div>
     
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="input-group input-group-static mb-4">
          <label>BIC</label>
          <Field
            type="text"
            class="form-control"
            v-model="user.bic"
            name="bic"
            id="bic"
          />
          <ErrorMessage name="bic" class="text-danger" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="input-group input-group-static mb-4">
          <label>Phone Number</label>
          <Field
            type="text"
            class="form-control"
            v-model="user.phone"
            name="phone"
            id="phone"
          />
          <ErrorMessage name="phone" class="text-danger" />
        </div>
      </div>
     
    </div>

    <Field
            type="hidden"
            class="form-control"
            v-model="user.id"
            name="id"
            id="id"
          />
   

        <div class="button-row d-flex justify-content-end mt-4">
          <button class="btn btn-sm btn-dark">
           Update User
          </button>
        </div>
      </Form>
</div>
  
</template>
<script>
import userService from "@/services/users.service";
import { Form, Field, ErrorMessage } from "vee-validate"; // Import necessary components
import * as Yup from 'yup';
import showSwal from "@/mixins/showSwal";
import Swal from 'sweetalert2';
export default {
  name: "Info2",
  
  components: {
    Form,
    Field,
    ErrorMessage // Register ErrorMessage component
  },
  data() {
    return {
      user: {},
      schema: Yup.object().shape({
        company_name: Yup.string().required("Username is a required input"),
        email: Yup.string().email("Email has to be a valid email address").required("Email is a required input"),
        name: Yup.string().required("Name is a required input"),
       


      }),
    };
  },
  methods: {
    async handleUpdate() {
      
      try {
       
          await this.$store.dispatch('users/updateCustomer',this.user);
            showSwal.methods.showSwal({
            type: "success",
            message: "User Updated Successfully",
            width: 500,
            timer: 160000, // 1 minute
            showConfirmButton: true
        });
        this.$router.push({ name: 'customers' })
 
    } catch (error) {
        console.error('Signup error:', error); // Log the error
        showSwal.methods.showSwal({
            type: "error",
            message: "Email Already Exists!",
            width: 500
        });
    }
  },
  async deleteItem(id) {
    
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    });

    if (result.isConfirmed) {
      try {
        await userService.deleteCustomer(id);
        Swal.fire('Deleted!', 'Customer has been deleted.', 'success');
        this.$router.push({ name: 'customers' })
      } catch (error) {
        console.error(`Error deleting customer with ID: ${id}`, error);
        Swal.fire('Error!', 'There was an error deleting the customer. Please try again.', 'error');
      }
    }
  },
  },
  async mounted() {
    const encodedVid = this.$route.params.id;
   
    
      try {
        const userId = atob(encodedVid);
        const users = await userService.getUserById(userId);
        
      
        this.user = users;
        
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    }
 
};
</script>
