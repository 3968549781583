<template>


  <div class="text-end">
    <router-link to="/vbots"> <button class="float-right btn btm-sm btn-success">
      Back to list
    </button></router-link>
  </div>
  <div class="p-4">
    <Form role="form"  @submit="handleAdd">
          <div class="row">
            <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
            <label>Bezeichnung</label>
            <Field
              type="text"
              class="form-control"
              v-model="vbot.bezeichnung"
              name="bezeichnung"
              id="bezeichnung"
            />
            <ErrorMessage name="bezeichnung" class="text-danger" />
          </div>
        </div>
         
      
      
            <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
            <label>Product Name</label>
            <Field as="select" class="form-control" v-model="vbot.product_name" name="product_name" id="product_name">
  <option value="" disabled>Select Product Name</option>
  <option value="KI-Phone-Basic">KI-Phone-Basic</option>
  <option value="KI-Phone-Advanced">KI-Phone-Advanced</option>
  
</Field>
            <ErrorMessage name="product_name" class="text-danger" />
          </div>
        </div>
        </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
            <label>sip benutzername</label>
            <Field
              type="text"
              class="form-control"
              v-model="vbot.sip_benutzername"
              name="sip_benutzername"
              id="sip_benutzername"
            />
            <ErrorMessage name="sip_benutzername" class="text-danger" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
            <label>sip password</label>
            <Field
              type="text"
              class="form-control"
              v-model="vbot.sip_passwd" 
              name="sip_passwd"
              id="sip_passwd"
            />
            <ErrorMessage name="sip_passwd" class="text-danger" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
            <label>sip registername</label>
            <Field
              type="text"
              class="form-control"
              v-model="vbot.sip_registername"
              name="sip_registername"
              id="sip_registername"
            />
            <ErrorMessage name="sip_registername" class="text-danger" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
            <label>sip phonenumber</label>
            <Field
              type="text"
              class="form-control"
              v-model="vbot.sip_phonenumber" 
              name="sip_phonenumber"
              id="sip_phonenumber"
            />
            <ErrorMessage name="sip_phonenumber" class="text-danger" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
            <label>sip server</label>
            <Field
              type="text"
              class="form-control"
              v-model="vbot.sip_server"
              name="sip_server"
              id="sip_server"
            />
            <ErrorMessage name="sip_server" class="text-danger" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
            <label>sip port</label>
            <Field
              type="text"
              class="form-control"
              v-model="vbot.sip_port" 
              name="sip_port"
              id="sip_port"
            />
            <ErrorMessage name="sip_port" class="text-danger" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
            <label>Email server</label>
            <Field
              type="text"
              class="form-control"
              v-model="vbot.e_mail_server"
              name="e_mail_server"
              id="e_mail_server"
            />
            <ErrorMessage name="e_mail_server" class="text-danger" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
            <label>Email user</label>
            <Field
              type="text"
              class="form-control"
              v-model="vbot.e_mail_user" 
              name="e_mail_user"
              id="e_mail_user"
            />
            <ErrorMessage name="e_mail_user" class="text-danger" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
            <label>Email password</label>
            <Field
              type="text"
              class="form-control"
              v-model="vbot.e_mail_passwd"
              name="e_mail_passwd"
              id="e_mail_passwd"
            />
            <ErrorMessage name="e_mail_passwd" class="text-danger" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
            <label>Email server port</label>
            <Field
              type="text"
              class="form-control"
              v-model="vbot.e_mail_server_port" 
              name="e_mail_server_port"
              id="e_mail_server_port"
            />
            <ErrorMessage name="e_mail_server_port" class="text-danger" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="input-group input-group-static mb-4">
            <label>Email server from</label>
            <Field
              type="text"
              class="form-control"
              v-model="vbot.e_mail_server_from"
              name="e_mail_server_from"
              id="e_mail_server_from"
            />
            <ErrorMessage name="e_mail_server_from" class="text-danger" />
          </div>
       
      </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="input-group input-group-static mb-4">
            <label>Ansagetext</label>
            <textarea
      class="form-control"
      v-model="vbot.ansagetext"
      name="ansagetext"
      id="ansagetext"
      rows="2"  
    ></textarea>
            <ErrorMessage name="ansagetext" class="text-danger" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="input-group input-group-static mb-4">
            <label>Prompt</label>
            <textarea
      class="form-control"
      v-model="vbot.prompt"
      name="prompt"
      id="prompt"
      rows="8"  
    ></textarea>
            <ErrorMessage name="prompt" class="text-danger" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
           
            <Field as="select" class="form-control" v-model="vbot.chatmodel" name="chatmodel" id="chatmodel">
              <option value="" disabled>Select chatmodel</option>
  <option value="GPT-3.5" >GPT-3.5</option>
  <option value="GPT-4o">GPT-4o</option>
  <option value="gpt-4o-mini">gpt-4o-mini</option>
  <option value="gpt-o3-mini">gpt-o3-mini</option>
  
  
</Field>
            <ErrorMessage name="chatmodel" class="text-danger" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
           
            <Field as="select" class="form-control" v-model="vbot.chatmodel_api" name="chatmodel_api" id="chatmodel_api">
              <option value="" disabled>Select chatmodel api</option>
  <option value="GPT-3.5" >GPT-3.5</option>
  <option value="GPT-4o">GPT-4o</option>
  <option value="gpt-4o-mini">gpt-4o-mini</option>
  <option value="gpt-o3-mini">gpt-o3-mini</option>
  
  
</Field>
            <ErrorMessage name="chatmodel_api " class="text-danger" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
           
           
            <Field as="select" class="form-control" v-model="vbot.sttmodel" name="sttmodel" id="sttmodel">
              <option value="" disabled>Select sttmodel</option>
  <option value="OpenAI">OpenAI</option>
  <option value="Microsoft">Microsoft</option>
  
  
</Field>
            <ErrorMessage name="sttmodel" class="text-danger" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
           
           
            <Field as="select" class="form-control" v-model="vbot.sttmodel_api" name="sttmodel_api" id="sttmodel_api">
              <option value="" disabled>Select sttmodel api</option>
  <option value="OpenAI">OpenAI</option>
  <option value="Microsoft">Microsoft</option>
  
  
</Field>
            <ErrorMessage name="sttmodel_api " class="text-danger" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
          
            <Field as="select" class="form-control" v-model="vbot.ttsmodel" name="ttsmodel" id="ttsmodel">
              <option value="" disabled>Select ttsmodel</option>
  <option value="OpenAI">OpenAI</option>
  <option value="Microsoft">Microsoft</option>
  
  
</Field>
            <ErrorMessage name="ttsmodel" class="text-danger" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
           
            <Field as="select" class="form-control" v-model="vbot.ttsmodel_api" name="ttsmodel_api" id="ttsmodel_api">
              <option value="" disabled>Select ttsmodel api</option>
  <option value="OpenAI">OpenAI</option>
  <option value="Microsoft">Microsoft</option>
  
  
</Field>
            <ErrorMessage name="ttsmodel_api " class="text-danger" />
          </div>
        </div>
      </div>
      <div class="row">
  <div class="col-md-6">
    <div class="input-group input-group-static mb-4">
            <label>PromptXT-URL</label>
            <Field
              type="text"
              class="form-control"
              v-model="vbot.prompturl"
              name="prompturl"
              id="prompturl"
            />
            <ErrorMessage name="prompturl" class="text-danger" />
          </div>
  </div>
  
  <div class="col-md-6">
          <div class="input-group input-group-static mb-4">
            <label>TransportType</label>
            <Field as="select" class="form-control" v-model="vbot.transmit_type" name="transmit_type" id="transmit_type">
  <option value="" disabled>Select TransportType</option>
  <option value="tcp">tcp</option>
  <option value="udp">udp</option>
  <option value="tls">tls</option>
  <option value="stcp">stcp</option>
  
</Field>
            <ErrorMessage name="transmit_type" class="text-danger" />
          </div>
        </div>

 
</div>
<div class="row">
  <div class="col-md-12">
    <div class="input-group input-group-static mb-4">
            <label>STUN Server</label>
            <Field
              type="text"
              class="form-control"
              v-model="vbot.stun_server"
              name="stun_server"
              id="stun_server"
            />
            <ErrorMessage name="stun_server" class="text-danger" />
          </div>
  </div>
</div>
      <div class="row">
  <div class="col-md-4">
    <div class="input-group input-group-static mb-4">
      <label class="form-check-label" for="attendedTransfer">Attended Transfer</label>
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          v-model="vbot.attendedTransfer"
          name="attendedTransfer"
          id="attendedTransfer"
        />
        <label class="form-check-label" for="attendedTransfer"></label>
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="input-group input-group-static mb-4">
      <label class="form-check-label" for="mail_certifikate_validation">Mail Certificate Validation</label>
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          v-model="vbot.mail_certifikate_validation"
          name="mail_certifikate_validation"
          id="mail_certifikate_validation"
        />
        <label class="form-check-label" for="mail_certifikate_validation"></label>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="input-group input-group-static mb-4">
      <label class="form-check-label" for="useSRTP">Use SRTP</label>
      <div class="form-check">
        <input
          type="checkbox"
          class="form-check-input"
          v-model="vbot.useSRTP"
          name="useSRTP"
          id="useSRTP"
        />
        <label class="form-check-label" for="useSRTP"></label>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="input-group input-group-static mb-4">
            <label>Outbound Proxy</label>
            <Field
              type="text"
              class="form-control"
              v-model="vbot.OutboundProxy"
              name="OutboundProxy"
              id="OutboundProxy"
            />
            <ErrorMessage name="OutboundProxy" class="text-danger" />
          </div>
  </div>

</div>
<div class="row">
    <div class="col-md-12">
      <div class="mb-4">
        <label class="form-label d-block">Category Terms (Max 5)</label>

        <div v-for="(term, index) in vbot.category_terms" :key="index" class="d-flex align-items-center mb-2">
          <div class="flex-grow-1 input-group input-group-static">
            <Field
              type="text"
              class="form-control border-0 border-bottom"
              v-model="vbot.category_terms[index]"
              :name="'category_terms_' + index"
              :id="'category_terms_' + index"
              @input="handleInputChange(index)"
            />
          </div>
          <button 
            type="button" 
            class="btn btn-sm btn-danger ms-2" 
            @click="deleteCategoryTerm(index)"
            v-if="vbot.category_terms.length > 1"
          >
            <i class="fas fa-minus"></i>
          </button>
        </div>

        <ErrorMessage name="category_terms" class="text-danger" />

        <button
          v-if="vbot.category_terms.length < 5"
          type="button"
          class="btn btn-sm btn-primary mt-2"
          @click="addCategoryTerm"
        >
          <i class="fas fa-plus"></i> Add Term
        </button>

        <div v-else>
          <small class="text-muted">Maximum 5 category terms allowed.</small>
        </div>
      </div>
    </div>
  </div>



          <div class="button-row d-flex justify-content-end mt-4">
            <button class="btn btn-sm btn-dark">
             Add Phonebot
            </button>
          </div>
        </Form>
  </div>
    
  </template>
  <script>
  
  import { Form, Field, ErrorMessage } from "vee-validate"; // Import necessary components
 
  import showSwal from "@/mixins/showSwal";
  
  export default {
    name: "Info1",
    components: {
      Form,
      Field,
      ErrorMessage // Register ErrorMessage component
    },
    data() {
      return {
        vbot: {
          category_terms: [] 
        },
       
      };
    },
    methods: {
      async handleAdd() {
    
        try {
         
            await this.$store.dispatch('users/addVbots', this.vbot);
              showSwal.methods.showSwal({
              type: "success",
              message: "Phonebot Created Successfully",
              width: 500,
              timer: 160000, // 1 minute
              showConfirmButton: true
          });
          this.$router.push({ name: 'vbots' })
   
      } catch (error) {
          console.error('Signup error:', error); // Log the error
          showSwal.methods.showSwal({
              type: "error",
              message: "Error in creating Phonebot!",
              width: 500
          });
      }
    },
    addCategoryTerm() {
      if (this.vbot.category_terms.length < 5) {
        this.vbot.category_terms.push(""); // Add a new empty string term
      }
    },
    deleteCategoryTerm(index) {
      if (this.vbot.category_terms.length > 1) {
        this.vbot.category_terms.splice(index, 1); // Remove the term
      }
    },
    handleInputChange(index) {
      let term = this.vbot.category_terms[index];

      // Trim quotes if the term starts or ends with quotes
      if (term && term.startsWith('"') && term.endsWith('"')) {
        this.vbot.category_terms[index] = term.slice(1, -1); // Remove quotes
      }
    },
  },
   
  };
  </script>
  