<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12"> 
        <div class="card">
          <div class="card-header border-bottom">
            <div class="user d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Customers</h5>
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table id="datatable-search" class="table table-flush table-bordered">
              <thead class="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="users.length === 0">
                  <td colspan="5" class="text-center">No users found.</td>
                </tr>
                <tr v-for="user in users" :key="user.id">
                  <td>{{ user.name }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.status  }}</td>
                  <td>{{ formatDate(user.created_at) }}</td>
                  <td>
                    <button @click="editUser(user.id)" class="btn btn-primary btn-sm">Edit</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import userService from "@/services/users.service";

export default {
  name: 'Users1',
  data() {
    return {
      users: [],
      dataTable: null,
    };
  },

  async mounted() {
    await this.fetchUsers();
  },

  methods: {
    async fetchUsers() {
      try {
        const response = await userService.getCustomers();
        this.users = response;
        this.initializeDataTable();
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },

    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString();
    },

    editUser(userId) {
      const encodedId = btoa(userId.toString());
      this.$router.push({ name: 'EditCustomer', params: { id: encodedId } });
    },

    initializeDataTable() {
      this.$nextTick(() => {
        if ($.fn.DataTable.isDataTable('#datatable-search')) {
          $('#datatable-search').DataTable().destroy();
        }

        this.dataTable = $('#datatable-search').DataTable({
          paging: true,  // Enable pagination
          searching: true,  // Enable search
          info: true,  // Enable info (e.g., "Showing 1 to 10 of 50 entries")
          data: this.users,
          columns: [
            { data: 'name' },
            { data: 'email' },
            { data: 'status'},
            { data: 'created_at', render: data => this.formatDate(data) },
            { data: null, render: (data, type, row) => `<button class='btn btn-primary btn-sm edit-btn' data-id='${row.id}'>Edit</button>` }
          ],
          createdRow: (row, data) => {
            $(row).find('.edit-btn').on('click', () => this.editUser(data.id));
          },
          drawCallback: () => {
            this.applyCustomBorders(); // Apply custom borders after the table is drawn
          }
        });
      });
    },

    refreshDataTable() {
      if (this.dataTable) {
        this.dataTable.clear().rows.add(this.users).draw();
      }
    },

    applyCustomBorders() {
      // Apply custom borders to the table body and header cells after DataTable is initialized
      $('#datatable-search th, #datatable-search td').css({
        'border': '1px solid #ddd',  // Light gray border
        'padding': '10px',            // Padding for table cells
      });

      // Apply a thicker left border to the first column
      $('#datatable-search td:first-child, #datatable-search th:first-child').css({
        'border-left': '2px solid #ddd',
      });

      // Optional: Apply a thicker top border for the header row
      $('#datatable-search th').css({
        'border-top': '2px solid #ddd',
      });
    },
  },
};
</script>

<style scoped>
/* Ensure table layout is fixed for better rendering */
#datatable-search {
  table-layout: fixed;
  width: 100%;
}

/* Apply general borders for table header and data cells */
#datatable-search th, #datatable-search td {
  border: 1px solid #ddd !important;  /* Ensure borders are applied with high priority */
  padding: 10px;
}

/* Apply a thicker left border to the first column */
#datatable-search td:first-child, #datatable-search th:first-child {
  border-left: 2px solid #ddd !important;
}

/* Apply a thicker top border to the header row */
#datatable-search th {
  border-top: 2px solid #ddd !important;
}

/* Optional: Style for table buttons */
#datatable-search td button {
  margin-right: 5px;
}
</style>
